import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, TextField, Typography, Paper, Pagination, Tooltip, MenuItem, Grid } from '@mui/material';
import { FlagIcon } from 'react-flag-kit';
import { useTheme } from '@mui/material/styles';
import { createCampaign, createCampaignIncentive } from '../services/api/ApiService';
import { getCatalog } from '../services/api/RunaService';
import { regionCodeToCountryName } from '../services/utilities/Countries';

function CampaignCreation({ onCreationSuccess }) {
  const [campaignName, setCampaignName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [incentiveDeadline, setIncentiveDeadline] = useState('');
  const [selectedDenomination, setSelectedDenomination] = useState({});
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const theme = useTheme();

  useEffect(() => {
    const fetchCatalog = async () => {
      try {
        const catalog = await getCatalog();
        setProducts(catalog);
      } catch (error) {
        console.error('Failed to fetch product catalog:', error);
      }
    };

    fetchCatalog();
  }, []);

  const allRegions = Array.from(new Set(products.flatMap((product) => product.regions))).sort();

  const handleAddProduct = (product) => {
    const existingProduct = selectedProducts.find((p) => p['product code'] === product['product code']);
    if (!existingProduct) {
      // Include selected denomination with the product details
      const productWithDenomination = {
        ...product,
        selectedDenomination:
          selectedDenomination[product['product code']] ||
          (product.denominations.type === 'fixed'
            ? product.denominations.available_list[0]
            : product.denominations.minimum_value),
      };
      setSelectedProducts([...selectedProducts, productWithDenomination]);
    }
  };

  const handleRemoveProduct = (productCode) => {
    setSelectedProducts(selectedProducts.filter((p) => p['product code'] !== productCode));
  };

  const handleDenominationChange = (e, productCode) => {
    const { value } = e.target;
    setSelectedDenomination((prevDenominations) => ({
      ...prevDenominations,
      [productCode]: value,
    }));
  };

  const handleRegionClick = (region) => {
    setSelectedRegions((prev) => (prev.includes(region) ? prev.filter((r) => r !== region) : [...prev, region]));
  };

  // Filter products based on regions and search term
  const filteredProducts = products.filter(
    (product) =>
      (selectedRegions.length === 0 || selectedRegions.every((region) => product.regions.includes(region))) &&
      product['product name'].toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Calculate the total number of pages
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  // Get the products for the current page
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!campaignName.trim()) {
      alert('Please enter a campaign name.');
      return;
    }

    setIsSubmitting(true);
    try {
      const campaignResponse = await createCampaign({ name: campaignName, incentive_deadline: incentiveDeadline });
      const campaignId = campaignResponse.id; // Assuming the response includes the campaign ID

      // Handle creation of campaign incentives here
      for (const product of selectedProducts) {
        const incentiveData = {
          campaign_id: campaignId,
          runa_product_code: product['product code'], // Ensure this matches your model's expectation
          regions: JSON.stringify(product['regions']), // Assuming regions need to be sent as JSON string
          denomination: product.selectedDenomination,
          currency: product.currency,
        };
        await createCampaignIncentive(incentiveData); // Create each incentive
      }

      // Navigate back to the campaigns page on successful creation
      navigate('/campaigns');
    } catch (error) {
      console.error('Failed to create campaign or incentives:', error);
      alert('Failed to create campaign or incentives. Please try again.');
    }
    setIsSubmitting(false);
  };

  return (
    <Box sx={{ p: 2, backgroundColor: '#f7f7f7' }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
              Create a New Campaign
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="campaign-name"
              label="Campaign Name"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              variant="outlined"
              size="small"
              disabled={isSubmitting}
              fullWidth
              sx={{ backgroundColor: 'white', borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="campaign-deadline"
              label="Incentive Deadline (hours from email sent)"
              type="number"
              value={incentiveDeadline}
              onChange={(e) => setIncentiveDeadline(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ backgroundColor: 'white', borderRadius: 1 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 2, borderBottom: '2px solid #ddd', paddingBottom: '8px', fontWeight: 'bold' }}
            >
              Pick an incentive for your recipients:
            </Typography>
            <TextField
              id="campaign-products"
              label="Search Products"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 2, backgroundColor: 'white', borderRadius: 1 }}
            />
            <Typography variant="body2" sx={{ mb: 1 }}>
              Filter incentives by country:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {allRegions.map((region) => (
                <Tooltip key={region} title={regionCodeToCountryName(region)} placement="top">
                  <Button
                    onClick={() => handleRegionClick(region)}
                    variant={selectedRegions.includes(region) ? 'contained' : 'outlined'}
                    color="primary"
                    sx={{
                      minWidth: '36px',
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      padding: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FlagIcon code={region} size={24} />
                    </Box>
                  </Button>
                </Tooltip>
              ))}
            </Box>
            <Box
              sx={{
                maxHeight: 450,
                overflowY: 'auto',
                p: 2,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              {paginatedProducts.map((product) => (
                <Box
                  key={product['product code']}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    my: 1,
                    p: 2,
                    '&:hover': { backgroundColor: '#f0f0f0' },
                    borderRadius: '4px',
                  }}
                >
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    {product['product name']} - {product.currency}
                  </Typography>
                  {product.denominations.type === 'fixed' ? (
                    <TextField
                      select
                      label="Denomination"
                      value={selectedDenomination[product['product code']] || ''}
                      onChange={(e) => handleDenominationChange(e, product['product code'])}
                      variant="outlined"
                      size="small"
                      sx={{ width: '40%', mx: 2 }}
                    >
                      {product.denominations.available_list.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      id="product-denomination"
                      label="Denomination (Custom Value)"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={selectedDenomination[product['product code']] || ''}
                      onChange={(e) => handleDenominationChange(e, product['product code'])}
                      InputProps={{
                        inputProps: {
                          min: product.denominations.minimum_value,
                          max: product.denominations.maximum_value,
                          step: 'any',
                        },
                        style: { textAlign: 'right' },
                      }}
                      sx={{ width: '40%', mx: 2 }}
                    />
                  )}
                  <Button id="add-product" variant="outlined" onClick={() => handleAddProduct(product)}>
                    Add
                  </Button>
                </Box>
              ))}
            </Box>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 2, borderBottom: '2px solid #ddd', paddingBottom: '8px', fontWeight: 'bold' }}
            >
              Selected Products for Campaign:
            </Typography>
            <Box sx={{ mt: 2 }}>
              {selectedProducts.map((product) => (
                <Box
                  key={product['product code']}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 1,
                    p: 2,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                >
                  <Typography variant="body2">
                    {`${product['product name']} - ${product.currency} - Denomination: ${product.selectedDenomination}`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleRemoveProduct(product['product code'])}
                    sx={{ ml: 2 }}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Button
          id="create-campaign-btn"
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !campaignName.trim()}
          sx={{ mt: 3, py: 1.5 }}
        >
          {isSubmitting ? 'Creating...' : 'Create Campaign'}
        </Button>
      </Box>
    </Box>
  );
}

export default CampaignCreation;
