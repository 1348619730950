import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faHubspot, faMailchimp } from '@fortawesome/free-brands-svg-icons';
import GmailThreadsTable from './GmailThreadsTable'; // Import your GmailThreadsTable component

function AddNewThread({ campaignId, onThreadsUpdated }) {
  const [showGmailThreads, setShowGmailThreads] = useState(false);

  const handleCreateGoogleThread = () => {
    setShowGmailThreads(!showGmailThreads);
  };

  return (
    <Box>
      <Typography variant="h6">Associate New Thread</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Attach unsent email drafts to incentivize recipients who meet your campaign conditions.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            onClick={handleCreateGoogleThread}
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faGoogle} />}
            sx={{ backgroundColor: '#4285F4', '&:hover': { backgroundColor: '#357ae8' } }}
          >
            Gmail Draft
          </Button>

          {/* HubSpot and MailChimp buttons are greyed out */}
          <Button
            disabled
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faHubspot} />}
            sx={{ backgroundColor: 'grey', '&:hover': { backgroundColor: 'darkgrey' } }}
          >
            HubSpot Thread
          </Button>

          <Button
            disabled
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faMailchimp} />}
            sx={{ backgroundColor: 'grey', '&:hover': { backgroundColor: 'darkgrey' } }}
          >
            MailChimp Thread
          </Button>
        </Box>
        {showGmailThreads && <GmailThreadsTable campaignId={campaignId} onThreadsUpdated={onThreadsUpdated} />}
      </Box>
    </Box>
  );
}

export default AddNewThread;
