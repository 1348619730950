import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { getCampaigns, deleteCampaign, getThreadsByCampaignId } from '../services/api/ApiService';

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    fetchCampaignsAndThreads();
  }, []);

  const fetchCampaignsAndThreads = async () => {
    try {
      setLoading(true);
      const allCampaigns = await getCampaigns();
      const campaignsWithThreadCounts = await Promise.all(
        allCampaigns.map(async (campaign) => {
          const threads = await getThreadsByCampaignId(campaign.id);
          return { ...campaign, threadCount: threads.length }; // Assuming threads is an array
        }),
      );
      setCampaigns(campaignsWithThreadCounts);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch campaigns and threads:', error);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      await deleteCampaign(campaignId);
      await fetchCampaignsAndThreads(); // Re-fetch campaigns to refresh the list after deletion
    } catch (error) {
      console.error('Failed to delete campaign:', error);
    }
  };

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h1" sx={{ pb: 1, borderBottom: '1px solid #ccc' }}>
          Campaigns
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Set up campaigns to incentivize your email recipients to reply faster.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Button
            id="create-campaign"
            variant="contained"
            color="primary"
            onClick={() => navigate('/campaigns/create')}
            sx={{ mb: 2 }} // Add some margin bottom for spacing
          >
            Create New Campaign
          </Button>
          <TableContainer sx={{ backgroundColor: '#fff' }}>
            <Typography variant="h6" sx={{ mb: 1, p: 3, borderBottom: '1px solid #ccc' }}>
              Campaigns
            </Typography>
            <Table aria-label="campaigns table">
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {campaigns.map((campaign) => (
                    <TableRow key={campaign.id}>
                      <TableCell component="th" scope="row">
                        <Button
                          id="campaign-name-btn"
                          variant="text"
                          onClick={() => navigate(`/campaigns/${campaign.id}`)}
                          color="primary"
                          sx={{
                            textTransform: 'none',
                            fontSize: 16,
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {campaign.name}
                        </Button>
                      </TableCell>
                      <TableCell align="right">{campaign.threadCount} threads</TableCell>
                      <TableCell align="right">
                        <Button variant="outlined" color="error" onClick={() => handleDeleteCampaign(campaign.id)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Campaigns;
