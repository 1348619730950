import React, { useEffect } from 'react';
import { Box, Typography, Container, CircularProgress } from '@mui/material';
import { getGoogleIntegrationURL } from '../../../services/api/ApiService'; // Adjust the import path as needed

function GoogleOAuthRedirect() {
  useEffect(() => {
    const fetchGoogleAuthUrl = async () => {
      try {
        const response = await getGoogleIntegrationURL();
        // Redirect the user to the Google OAuth URL as soon as it's obtained
        window.location.href = response.auth_url;
      } catch (error) {
        console.error('Failed to fetch Google OAuth URL:', error);
      }
    };

    fetchGoogleAuthUrl();
  }, []);

  // Optionally, display a loading indicator or message while waiting for the URL
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h1">
          Redirecting to Google...
        </Typography>
        <CircularProgress />
      </Box>
    </Container>
  );
}

export default GoogleOAuthRedirect;
