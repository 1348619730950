import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useAuth } from '../services/auth/AuthService';

function Dashboard() {
  const { userDetails, deleteUser, isAuthenticated } = useAuth();
  return (
    <Box
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Adjust the height as needed
        gap: 2, // Adds space between the icon and text
      }}
    >
      <EngineeringIcon sx={{ fontSize: 100, color: 'lightgrey' }} />
      <Typography variant="h6" component="p" textAlign="center" sx={{ p: 2 }}>
        This dashboard is currently under development and Speedable is in beta. Stay tuned for updates.
      </Typography>
    </Box>
  );
}

export default Dashboard;
