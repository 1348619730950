import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import { getRewards } from '../services/api/ApiService'; // Adjust the import path as needed
import '../styles/Rewards.css'; // Correct path for the CSS file

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [newlyAddedRewardIds, setNewlyAddedRewardIds] = useState([]); // Track newly added reward IDs
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true); // Track if this is the first load

  const fetchRewards = async () => {
    try {
      const rewardsResponse = await getRewards(); // Adjust this to match your actual API call
      const newRewards = rewardsResponse.rewards;

      if (!isFirstLoad) {
        // Compare the new rewards to the current ones to detect newly added rewards
        const newRewardIds = newRewards.map((reward) => reward.id);
        const existingRewardIds = rewards.map((reward) => reward.id);
        const addedRewards = newRewardIds.filter((id) => !existingRewardIds.includes(id));

        if (addedRewards.length > 0) {
          setNewlyAddedRewardIds(addedRewards); // Set the newly added rewards
          setTimeout(() => {
            setNewlyAddedRewardIds([]); // Clear the newly added class after the fade effect
          }, 3000); // Keep the highlight for 3 seconds
        }
      }

      // Only update the rewards if the data has actually changed
      if (JSON.stringify(newRewards) !== JSON.stringify(rewards)) {
        setRewards(newRewards);
      }

      // After the first load, set the flag to false
      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
      localStorage.setItem('lastVisitedRewardCount', newRewards.length || 0);

    } catch (error) {
      setError('Failed to load rewards.');
    } finally {
      setIsLoading(false); // Set loading to false only once after the initial load
    }
  };

  useEffect(() => {
    fetchRewards();

    // Poll every 2 seconds (as requested)
    const interval = setInterval(() => {
      fetchRewards();
    }, 2000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [rewards]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 1, p: 3, borderBottom: '1px solid #ccc' }}>
        All Rewards
      </Typography>
      {isLoading && rewards.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          {/* Show loader only on the first load */}
          <Typography>Loading...</Typography>
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : rewards.length > 0 ? (
        <TableContainer>
          <Table aria-label="all rewards">
            <TableHead>
              <TableRow>
                <TableCell>Email Address</TableCell>
                <TableCell>Reward Sent</TableCell>
                <TableCell>Reward Claimed</TableCell>
                <TableCell>Claimed Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewards.map((reward) => (
                <TableRow
                  key={reward.id}
                  className={newlyAddedRewardIds.includes(reward.id) ? 'fade-in' : ''}
                >
                  <TableCell>{reward.email_address}</TableCell>
                  <TableCell>{reward.reward}</TableCell>
                  <TableCell>{reward.amount_billed}</TableCell>
                  <TableCell>
                    {reward.reward_claimed_timestamp
                      ? new Date(reward.reward_claimed_timestamp).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No rewards available.</Typography>
      )}
    </Paper>
  );
};

export default Rewards;