import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faHubspot, faMailchimp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService'; // Adjust the import path as necessary

function Integrations() {
  const navigate = useNavigate();
  const { userDetails } = useAuth(); // Access userDetails via useAuth
  const isGoogleIntegrated = !!userDetails?.google_refresh_token; // Check if the Google integration exists based on refreshToken

  const handleGoogleIntegration = () => {
    navigate('/google/begin-integration');
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ p: 2 }}>
        Integrations
      </Typography>
      <Typography variant="body1" component="h1" sx={{ p: 2 }}>
        Here you can manage the integrated services you can use speedable with.
      </Typography>
      <Box sx={{ p: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography>
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} /> Gmail
              </Typography>
              {isGoogleIntegrated && (
                <Box id="google-integration" display="flex" alignItems="center" sx={{ marginRight: 2 }}>
                  <Typography variant="subtitle1" component="span" sx={{ mr: 1 }}>
                    Integrated
                  </Typography>
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
              <Button
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                onClick={handleGoogleIntegration}
                sx={{
                  backgroundColor: '#1a73e8', // Google's blue color
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#135ba1',
                  },
                }}
              >
                {isGoogleIntegrated ? 'Refresh Integration' : 'Integrate with Google'}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>
              <FontAwesomeIcon icon={faHubspot} style={{ marginRight: 8 }} /> HubSpot
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>HubSpot integration coming soon.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>
              <FontAwesomeIcon icon={faMailchimp} style={{ marginRight: 8 }} /> MailChimp
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>MailChimp integration coming soon.</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default Integrations;
