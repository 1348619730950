const BASE_API_URL = process.env.SPEEDABLE_API_URL; // Make sure this is defined or imported

// Function to get the stored JWT token
function getToken() {
  return localStorage.getItem('jwtToken');
}

// Helper function to initialize fetch request headers with Authorization
function getAuthHeaders(contentType = 'application/json') {
  const token = getToken();
  const headers = {
    'Content-Type': contentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

// Function to get the catalog from your own API, adapted to use getAuthHeaders for authentication
const getCatalog = async () => {
  const endpoint = 'runa/catalog'; // Adjust if the actual endpoint differs
  return fetchData(endpoint);
};

// Adapted fetchData to use the existing API structure and authorization headers
const fetchData = async (endpoint, options = {}) => {
  const url = `${BASE_API_URL}/${endpoint}`;

  // Utilizing getAuthHeaders to include authorization tokens
  const finalOptions = {
    ...options,
    headers: getAuthHeaders('application/json'), // Assuming getAuthHeaders sets the correct Authorization header
  };

  try {
    const response = await fetch(url, finalOptions);
    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`);
    }
    return await response.json(); // Assumes the response is JSON
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error; // Re-throw to let the calling function handle it
  }
};

export { fetchData, getCatalog }; // Export fetchData if needed elsewhere
