import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { redeemReward, getCampaignRewards, assignRewardToRecipient } from '../services/api/ApiService';

function RedeemPage() {
  const { campaignId, threadId, recipientId } = useParams();
  const theme = useTheme();
  const [statusMessage, setStatusMessage] = useState('Please select your reward and region.');
  const [rewards, setRewards] = useState([]);
  const [selectedReward, setSelectedReward] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.body.style.transition = 'background-color 1s ease';
    document.body.style.backgroundColor = theme.palette.secondary.main;

    const fetchRewards = async () => {
      try {
        const result = await getCampaignRewards(campaignId);
        setRewards(result);
        if (result.length > 0) {
          setSelectedReward(result[0].id); // Default to the first reward
          setRegions(result[0].regions); // Set regions for the first reward
          setSelectedRegion(result[0].regions[0].regional_product_id); // Default to the first region's regional_product_id
        } else {
          setStatusMessage('No rewards available for this campaign.');
          setError(true);
        }
      } catch (error) {
        console.error('Error fetching rewards:', error);
        setStatusMessage('Failed to fetch rewards. Please try again.');
        setError(true);
      }
    };

    fetchRewards();

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.transition = '';
    };
  }, [campaignId, theme]);

  const handleRewardChange = (event) => {
    const newSelectedReward = rewards.find((reward) => reward.id === event.target.value);
    setSelectedReward(newSelectedReward.id);
    setRegions(newSelectedReward.regions);
    setSelectedRegion(newSelectedReward.regions[0].regional_product_id); // Default to the first region's regional_product_id
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleRedeemReward = async () => {
    try {
      await assignRewardToRecipient(recipientId, selectedRegion);
      const redeemResult = await redeemReward(campaignId, threadId, recipientId);
      setStatusMessage(redeemResult.message);
    } catch (error) {
      console.error('Redemption error:', error);
      setStatusMessage('Failed to rewards the reward. Please try again.');
      setError(true);
    }
  };

  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} style={{ padding: 32, width: '100%', maxWidth: 400 }}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
              Reward Redemption
            </Typography>
            <FontAwesomeIcon
              icon={faGift}
              style={{
                fontSize: 100,
                color: theme.palette.primary.main,
                display: 'block',
                margin: '20px auto',
              }}
            />
            {rewards.length > 0 && (
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="reward-select-label">Select Your Reward</InputLabel>
                <Select
                  labelId="reward-select-label"
                  id="reward-select"
                  value={selectedReward}
                  label="Select Your Reward"
                  onChange={handleRewardChange}
                >
                  {rewards.map((reward) => (
                    <MenuItem key={reward.id} value={reward.id}>
                      {reward.product_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {regions.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="region-select-label">Select Your Region</InputLabel>
                <Select
                  labelId="region-select-label"
                  id="region-select"
                  value={selectedRegion}
                  label="Select Your Region"
                  onChange={handleRegionChange}
                >
                  {regions.map((region) => (
                    <MenuItem key={region.regional_product_id} value={region.regional_product_id}>
                      {region.country_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Typography variant="body1" component="p" style={{ textAlign: 'center', marginBottom: 20 }}>
              {statusMessage}
            </Typography>
            <Button
              onClick={handleRedeemReward}
              fullWidth
              variant="contained"
              color={error ? 'error' : 'primary'}
              style={{ marginTop: 24 }}
            >
              Redeem
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RedeemPage;
