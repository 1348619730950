import React from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import { useAuth } from '../services/auth/AuthService'; // Adjust the import path as needed

function Profile() {
  const { userDetails, deleteUser, isAuthenticated } = useAuth(); // Destructure userDetails from useAuth

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 4,
        }}
      >
        <Typography variant="h4" component="h1">
          Profile Details
        </Typography>
        <Box>
          <Typography variant="h6">Email:</Typography>
          <Typography>{userDetails?.email || 'No email found'}</Typography>
        </Box>
        <Button id="delete-account-btn" onClick={() => deleteUser(userDetails.email)} variant="contained" color="error">
          Delete User
        </Button>
      </Box>
    </Container>
  );
}

export default Profile;
