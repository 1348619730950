import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import {
  savePaymentMethod,
  getPaymentMethods,
  deletePaymentMethod,
  setDefaultPaymentMethod,
} from '../services/api/ApiService'; // Update the path as needed
import { Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const Billing = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const options = {
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };
  const fetchPaymentMethods = async () => {
    try {
      const response = await getPaymentMethods();
      if (response.data) {
        setPaymentMethods(response.data);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const handleSavePaymentMethod = async (paymentMethodId) => {
    try {
      await savePaymentMethod(paymentMethodId);
      fetchPaymentMethods(); // Refresh list after adding
      console.log('Payment method saved successfully');
    } catch (error) {
      console.error('Error saving payment method:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {paymentMethods.map((pm) => (
            <Card key={pm.id} sx={{ marginBottom: 2 }}>
              <CardContent id="payment-card">
                <Typography variant="h6" gutterBottom>
                  Card: **** **** **** {pm.card.last4}
                </Typography>
                <Typography variant="body1">Type: {pm.card.display_brand || pm.card.brand}</Typography>
                <Typography variant="body2">
                  Expiry: {pm.card.exp_month}/{pm.card.exp_year}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => deletePaymentMethod(pm.id)}
                  sx={{ marginRight: 1 }}
                >
                  Delete
                </Button>
                <Button variant="contained" onClick={() => setDefaultPaymentMethod(pm.id)}>
                  Set as Default
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Add New Payment Method
              </Typography>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm onSavePaymentMethod={handleSavePaymentMethod} />
              </Elements>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Billing;
