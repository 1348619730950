// create simple react page to logout and redirect to index

import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../services/auth/AuthService';

function Logout() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
    navigate('/');
  }, [signOut, navigate]);

  return null;
}

export default Logout;
