import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import { fetchGmailDrafts, createThread } from '../../services/api/ApiService'; // Adjust the import path

function GmailDraftsTable(props) {
  const [allDrafts, setAllDrafts] = useState([]);
  const [displayedDrafts, setDisplayedDrafts] = useState([]);
  const [selectedDrafts, setSelectedDrafts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false); // State to track when drafts are being added
  const pageSize = 10; // Number of items per page

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const draftsData = await fetchGmailDrafts();
        setAllDrafts(draftsData.drafts);
      } catch (error) {
        console.error('Failed to fetch drafts:', error);
      }
      setIsLoading(false);
    }

    loadData();
  }, []);

  useEffect(() => {
    const filteredDrafts = searchTerm
      ? allDrafts.filter((draft) => draft.subject.toLowerCase().includes(searchTerm.toLowerCase()))
      : allDrafts;

    const newDisplayedDrafts = filteredDrafts.slice(page * pageSize, (page + 1) * pageSize);
    setDisplayedDrafts(newDisplayedDrafts);
    setSelectedDrafts([]); // Reset selection upon page change or search
  }, [allDrafts, searchTerm, page, pageSize]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleNextPage = () => setPage((prevPage) => prevPage + 1);
  const handlePreviousPage = () => setPage((prevPage) => Math.max(prevPage - 1, 0));

  const handleSelectDraft = (event, draftId) => {
    event.stopPropagation(); // Prevent row click event
    const currentIndex = selectedDrafts.indexOf(draftId);
    const newChecked = [...selectedDrafts];

    if (currentIndex === -1) {
      newChecked.push(draftId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedDrafts(newChecked);
  };

  const handleAddDrafts = async () => {
    setIsAdding(true);
    try {
      for (const draftId of selectedDrafts) {
        const draft = allDrafts.find((draft) => draft.id === draftId);

        // Ensure you have the required fields for creating a thread
        const threadData = {
          summary: draft.subject, // Assuming the draft subject can be the summary
          type: 'email', // Assuming the type of thread. Adjust accordingly.
          campaign_id: props.campaignId, // Using the campaign ID passed as a prop
          meta: { gmail_message_id: draftId }, // Optional, based on your backend needs
        };

        console.log(threadData); // To verify data structure
        await createThread(threadData);
        console.log(`Draft ${draftId} added as a thread`);
      }

      // After successfully adding drafts, call the refresh function
      if (props.onThreadsUpdated) {
        props.onThreadsUpdated();
      }
    } catch (error) {
      console.error('Error adding draft:', error);
    } finally {
      setIsAdding(false);
      setSelectedDrafts([]);
    }
  };

  if (isAdding) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Adding Drafts to Campaign...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Gmail Drafts</Typography>
        <Button variant="contained" color="primary" onClick={handleAddDrafts} disabled={selectedDrafts.length === 0}>
          Add Drafts
        </Button>
      </Box>
      <TextField
        label="Search Drafts"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ my: 2 }}
      />
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 4,
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Loading drafts...</Typography>
        </Box>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Draft Subject</TableCell>
                <TableCell padding="checkbox" align="right" sx={{ width: 100 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedDrafts.map((draft) => (
                <TableRow key={draft.id} hover>
                  <TableCell>{draft.subject}</TableCell>
                  <TableCell padding="checkbox" align="right" sx={{ width: 100 }}>
                    <Checkbox
                      color="primary"
                      checked={selectedDrafts.indexOf(draft.id) !== -1}
                      onChange={(event) => handleSelectDraft(event, draft.id)}
                      inputProps={{ 'aria-labelledby': `checkbox-list-label-${draft.id}` }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={handlePreviousPage} disabled={page === 0 || isLoading} sx={{ mr: 1 }}>
              Previous
            </Button>
            <Button onClick={handleNextPage} disabled={displayedDrafts.length < pageSize || isLoading}>
              Next
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default GmailDraftsTable;
