import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, Paper, Typography, Container } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService';

function PrivacyPolicyPage() {
  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{
              fontSize: 300,
              color: '#fff', // Adjust color as needed
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} style={{ padding: 32, width: '100%', maxWidth: 400 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Speedable Privacy Policy
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PrivacyPolicyPage;
