import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useAuth } from '../services/auth/AuthService';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { requestResetPassword, resetPassword } = useAuth(); // Assuming you have a resetPassword function in your auth service
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await requestResetPassword(email);
      setMessage(`Password reset link sent to ${email.toLowerCase()}`);
      setIsSubmitted(true);
    } catch (error) {
      setMessage(error.message || 'Failed to send password reset email.');
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      await resetPassword(email, resetCode, newPassword);
      setMessage('Password reset successful');
      navigate('/login');
    } catch (error) {
      setMessage(error.message || 'Failed to reset password');
    }
  };
  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={6} style={{ padding: 32 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Forgot Password
            </Typography>
            {isSubmitted ? (
              <>
                <Typography style={{ marginTop: 16 }}>
                  If the email address is registered, you will receive a password reset code.
                </Typography>
                <form onSubmit={handleResetPassword} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="resetCode"
                    label="Reset Code"
                    name="resetCode"
                    autoFocus
                    value={resetCode}
                    onChange={(e) => setResetCode(e.target.value)}
                  />
                  <TextField
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <TextField
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmNewPassword"
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  {message && <Typography style={{ marginTop: 16, color: 'red' }}>{message}</Typography>}
                  <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 24 }}>
                    Reset Password
                  </Button>
                </form>
              </>
            ) : (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {message && <Typography style={{ marginTop: 16, color: 'red' }}>{message}</Typography>}
                <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 24 }}>
                  Send Reset Link
                </Button>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
